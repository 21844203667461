.comment-input {
    border: none;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    box-shadow: none;
    outline: none;
    transition: all 0.3s ease;
    resize: none;
  }
  
  .comment-input:focus {
    border-bottom: 2px solid #007bff;
    box-shadow: none;
  }
  
  .comment-input::placeholder {
    color: #888;
  }
  