  .jumbotron {
    background: url('../../public/back1.jpg') no-repeat center center;
    color: white;
    background-size: 100% auto;
    max-width: 700px;
  }
  
  .jumbotron h2 {
    font-size: 3rem;
  }
  
  .jumbotron p {
    font-size: 1.5rem;
  }
  